<template>
  <div class="table-container">
    <div class="table-wrapper">
      <v-data-table
          :show-select="select_active"

          :value="selected"
          @input="$emit('update:selected', $event)"

          :headers="active_headers"
          :items="items"
          item-key="id"

          class="elevation-0 table custom-table"
          :class="{'table__without-checkbox': !select_active}"
          checkbox-color="primary"
          :header-props="{ sortIcon: '$vuetify.icons.iconsort' }"
          fixed-header
          disable-pagination
          :hide-default-footer="true"
          :loading="loading"
          :loading-text="loadingItemsText"
          :no-data-text="noDataText"
          :no-results-text="noSearchResultsText"
          :options.sync="options"
          :sort-desc="sortDescending"
          :sort-by="currentSort"
          @update:options="handleOptionsUpdate"

          :search="searchInTable"
      >

        <template v-slot:item="{ item, headers, isSelected, select }">
          <tr :data-id="item.id">
              <template
                v-for="( header, index ) in headers"
              >
                <td
                  :key="index"
                  v-if="!header.type && select_active"
                >
                  <v-simple-checkbox
                    :value="isSelected"
                    @input="select($event)"
                    color="primary"
                    :ripple="false"
                  ></v-simple-checkbox>
                </td>

                <td
                  :key="index"
                  v-else-if="header.name === 'active'"
                >
                  <v-switch
                    inset
                    color="primary"
                    :input-value="item[header.value]"
                    @change="toggleActiveClient({user_id: item['id'], toggle: $event } )"
                  />
                </td>

                <td
                  :key="index"
                  v-else-if="header.name === 'channel'"
                >
                  <v-layout align-center justify-center>
                    <v-avatar
                      :key="formatChannelIcons(item).name"
                      size="20"
                    >
                      <img
                        :src="formatChannelIcons(item).icon"
                        :title="formatChannelIcons(item).name"
                        :alt="formatChannelIcons(item).name"
                      />
                    </v-avatar>
                  </v-layout>
                </td>

                <td
                  :key="index"
                  v-else-if="header.type === 'DATE'"
                >

                  <!-- <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip 
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        @mouseenter="handleHover"
                      >
                        {{ formatDate(item[header.value], true) }}
                      </v-chip>
                    </template>
                    <span>{{ formatDate(item[header.value]) }}</span>
                  </v-tooltip> -->

                  <DynamicTableCell
                    :value="item[header.value]"
                    :itemId="item.id"
                    :itemName="header.value"
                    :itemType="header.type"
                  />

                </td>

                <td
                  :key="index"
                  v-else-if="header.type === 'actions'"
                >
                  <v-layout align-center justify-center>
                    <v-btn
                      icon
                      color="green"
                      class="mt-1"
                      @click="editItem(item.id)"
                      :title="i18n.t('modules.customdata.entity.edit')"
                    ><v-icon small>$iconedit</v-icon></v-btn>
                    <!-- <v-btn
                      icon
                      color="red"
                      class="mt-1"
                      @click="deleteItem(item.id)"
                      :title="i18n.t('modules.customdata.entity.delete')"
                    ><v-icon small>$icondelete</v-icon></v-btn> -->
                  </v-layout>
                </td>

                <td
                  v-else
                  :key="index"
                >
                  <DynamicTableCell
                    :value="item[header.value]"
                    :itemId="item.id"
                    :itemName="header.value"
                    :itemType="header.type"
                    :itemEditable="header.non_editable"
                    :itemDisplayType="header.display_type"
                    :itemClickAction="header.click_action"
                    @open-edit="editItem"
                  />
              </td>
              </template>
            </tr>
        </template>

        <template v-slot:body.append="{headers}">
          <tr
            v-if="propTotalRow"
            class="total-row"
          >
            <td 
              v-for="(header, index) in headers" 
              :key="index"
              class="total-col"
              :class="{'total-col--hidden': header.value == 'data-table-select' || header.value == 'id' || header.value == 'actions'}"
            >
              <template
                v-if="header.value !== 'data-table-select' && header.value !== 'id' && header.value !== 'actions'"
              >
                {{getColumnSumByHeader(header)}}
              </template>
            </td>
          </tr>
        </template>

      </v-data-table>
    </div>

    <div
        class="table-pagination"
        v-if="selected.length < 1"
    >
      <div class="text-caption mr-5">
        {{ $t('modules.customdata.table.pagination.showed') }}: {{ itemsLength }} {{ $t('modules.customdata.table.pagination.items') }}
      </div>

      <v-spacer></v-spacer>

      {{ /* Select Per Page */ }}
      <div class="d-flex align-center">
        <!-- <div class="text-body-2 mr-2">{{ $t('modules.customdata.table.pagination.rows_per_page') }}:</div> -->
        <v-select
            item-text="name"
            item-value="value"
            v-model="selectDefaultPerPage"
            :items="itemsPerPage"
            @change="resetPagination"
            dense
            outlined
            height="32"
            hide-details
            class="select-per-page"
        ></v-select>
      </div>
      {{ /* end Select Per Page */ }}

      <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="(pageCount >= 3) ? '5' : '0'"
          @input="handlePageChange"
          circle
      ></v-pagination>
    </div>

    <div
      class="table-pagination"
      v-else
    >
      <div class="text-caption">
        {{ $t('modules.customdata.table.pagination.selected') }}: {{ selected.length }} {{ $t('modules.customdata.table.pagination.items') }}
      </div>
      <v-spacer />
    </div>

  </div>
</template>

<script>
import i18n from "@/lang/i18n"
import { mapGetters } from "vuex";
import _ from 'lodash'

import DynamicTableCell from "@/modules/CustomData/components/DynamicTableCell.vue";

const telegramIcon = require('@/assets/logo-telegram.svg');
const viberIcon = require('@/assets/logo-viber.svg');
const messengerIcon = require('@/assets/logo-messenger.svg');
const whatsappIcon = require('@/assets/logo-whatsapp.svg');
const widgetIcon = require('@/assets/logo-kwizbot.svg');

export default {
  name: "DynamicTable",

  components: {
    DynamicTableCell
  },

  props: {
    columns_data: {
      type: Array,
      default: () => [],
    },
    select_active: {
      type:Boolean,
      default: false
    },
    selected: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    // selectedHeaders: {
    //   type: Array,
    //   default: () => [],
    // },
    loadingItemsText: {
      type: String,
      default: i18n.t("modules.customdata.table.loading_items"),
    },
    noDataText: {
      type: String,
      default: i18n.t("modules.customdata.table.no_data_text"),
    },
    noSearchResultsText: {
      type: String,
      default: i18n.t("modules.customdata.table.no_search_results"),
    },
    itemsLength: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: true,
    },

    defaultPerPage: {
      type: Number,
      default: 5,
    },
    itemsPerPage: {
      type: Array,
      default: () => [],
    },

    pageCount: {
      type: Number,
      default: 0,
    },

    defaultSort: {
      type: String || Array,
      default: () => '' || []
    },

    searchInTable: {
      type: String,
      default: '0',
    },

    propTotalRow: {
      type:Boolean,
      default: false
    },
  },

  data: () => ({
    // selectedRows: [],
    page: 1,
    itemsCount: 0,
    options:{},
    i18n,
    currentSort: '',
    sortDescending: true,
  }),
  watch: {
    options: {
      handler (val, valBefore) {
        if (valBefore?.page)
          this.$emit("sortOptions", this.options);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      'custom_data_columns_data',
      'custom_data_default_columns_data'
    ]),
    // showHeaders() {
    //   console.log(this.selectedHeaders, 'showHeaders')
    //   return (this.selectedHeaders)
    //     ? this.selectedHeaders.filter((s) => s.active === true)
    //     : this.headers;
    // },

    // selectVal: {
    //   get() {
    //     return this.selectedRows;
    //   },
    //   set(val) {
    //     this.selectedRows = val;
    //     this.$emit("selectedData", val);
    //   },
    // },

    /**
     * @name selectDefaultPerPage
     * @description v-model for select Per Page Items
     */
    selectDefaultPerPage: {
      get() {
        return this.defaultPerPage;
      },
      set(value) {
        this.$emit("change-default-per-page", value);
      },
    },

    active_headers() {
      let data = []
      if(this.columns_data.length) {
        data = this.columns_data
      } else {
        data = this.custom_data_columns_data
      }

      let arr = data.filter((c) => c.active === true)
      return _.sortBy(arr, 'position')
    },

    defaultSortOptions() {
      if (Array.isArray(this.defaultSort)) {
        return {
          sortBy: this.defaultSort[0],
          descending: this.sortDescending,
        };
      } else {
        return {
          sortBy: this.defaultSort,
          descending: this.sortDescending,
        };
      }
    },
  },

  methods: {
    /**
     * @description reset Pager on @change perPage v-select
     */
    resetPagination() {
      this.page = 1;
    },

    /**
     * @name handlePageChange
     * @param value
     * @description call on input/change for pagination and send to parent
     */
    handlePageChange(value) {
      this.$emit("change-page", value);
    },

    editItem(value) {
      this.$emit('edit-item', value)
    },
    deleteItem(value) {
      this.$emit('delete-item', value)
    },

    toggleActiveClient(value) {
      this.$emit('toggle_active-client', value)
    },

    formatChannelIcons(user) {
      switch (user.channel) {
        case 'telegram':
          return {
            icon: telegramIcon,
            name: 'Telegram',
          };

        case 'viber':
          return {
            icon: viberIcon,
            name: 'Viber',
          };

        case 'facebook':
          return {
            icon: messengerIcon,
            name: 'Facebook',
          };

        case 'whatsapp':
          return {
            icon: whatsappIcon,
            name: 'Whatsapp',
          };

        case 'widget':
          return {
            icon: widgetIcon,
            name: 'Widget',
          };
        default:
          return {
            icon: widgetIcon,
            name: 'Widget',
          };
      }
    },

    getColumnSumByHeader(header) {
      const sum = this.custom_data_default_columns_data.find((c) => c.value === header.value)?.sum;
      const multiplier = Math.pow(10, 2);
      return Math.round(sum * multiplier) / multiplier || '';
    },

    handleOptionsUpdate(newOptions) {
      if (!newOptions.sortBy?.length || !newOptions.sortDesc?.length) {
        this.currentSort = [this.defaultSortOptions.sortBy];
        this.sortDescending = [this.defaultSortOptions.descending];
      }
    }

  },

  created() {
    this.currentSort = this.defaultSortOptions.sortBy;
    this.sortDescending = this.defaultSortOptions.descending;
  },
};
</script>

<style lang="scss" scoped>
.table-container::v-deep {
  display: flex;
  flex-direction: column;
  flex: auto;
}

/* Pagination */
.table-pagination::v-deep {
  // border-top: 1px solid var(--v-gray-base);
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .select-per-page{
    max-width: 70px;
    &.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
      padding: 0 8px 0 12px;
      min-height: 32px;
    }
    &.v-select.v-input--dense .v-select__selection--comma{
      margin: 0;
      font-size: 14px;
      overflow: visible;
    }
    .v-input__append-inner{
      margin-top: 4px !important;
    }

    .v-select__selections {
      flex-wrap: nowrap;
    }
  }

  .v-pagination__item{
    box-shadow: none;
    background: none;
    min-width: 28px;
    max-height: 28px;
    border-radius: 2px;
    border: 1px solid transparent;
    background-color: transparent !important;

    &.v-pagination__item--active {
      border-color: var(--v-primary-base);

      color: var(--v-primary-base);
    }
  }

  .v-pagination {
    .v-pagination__navigation {
      background: transparent;
      box-shadow: none;
    }
  }
}
/* end Pagination */

.table-wrapper {
  height: 300px;
  flex: auto;

  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  ::-webkit-scrollbar-track {
    background: var(--v-gray-base);
  }
  ::-webkit-scrollbar-thumb {
    background: var(--v-primary-base);
    border-radius: 2px;
  }
}
.table::v-deep {
  flex: auto;
  height: 100%;

  table {
    border-top: none;

    @media screen and (min-width: 1300px) {
      zoom: 0.9;
    }
  }

  .v-input--selection-controls__input {
    margin-right: 8px;
  }

  .v-data-table__wrapper {
    height: 100%;
  }
  th{
    border-top: 1px solid var(--v-blueHaze-base);

    /* Sort icon */
    &:not(:first-child) {
      padding-right: 27px !important;
      & > span.v-icon {
        position: absolute;
        right: 12px;
        top: 18px;

        width: 10px !important;
        height: 12px !important;

        svg {
          width: 10px !important;
          height: 12px !important;
        }
      }
    }
  }
  th, td{
    /* style for checkbox selected*/
    &:nth-child(1) {
      padding-left: 10px;
      padding-right: 2px;
    }
    &:nth-child(2) {
      padding-left: 10px;
      border-left: none;
    }
  }

  &.table__without-checkbox {
    th{
      &:first-child {
        padding-right: 27px !important;
        & > span.v-icon {
          position: absolute;
          right: 12px;
          top: 18px;

          width: 10px !important;
          height: 12px !important;

          svg {
            width: 10px !important;
            height: 12px !important;
          }
        }
      }
    }
  }
}
/* Custom table cols */
.table-col-status {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-table::v-deep {
  border-radius: 0;
  background: none;

  &.theme--light {
    table {
      background: white;
    }
  }

  table {
    border: 1px solid var(--v-blueHaze-base);
    border-top: none;
  }
  tr {
    th {
      // background-color: var(--v-gray2-base) !important;
      // white-space: nowrap;
      font-weight: 700;

    }
    td *:not(i, button) {
      font-size: 14px !important;
    }
    th, td {
      font-size: 14px !important;
      color: var(--v-black-base) !important;
      padding: 0 10px !important;

      &:not(:last-child) {
        border-right: 1px solid var(--v-blueHaze-base);
      }
    }
    &:first-child {

    }
    &:last-child {
      td {
        //border-bottom: 1px solid var(--v-gray-base);
      }
    }
    &:hover {
      background: var(--v-athensGray-base) !important;
    }
  }

  .v-date-picker-table td {
    padding: 0 !important;
  }

  .v-data-table-header {
    th {
      box-shadow: 0px 0px 25px -8px var(--v-ghost-base), inset 0 -1px 0 rgba(0, 0, 0, 0.12) !important;
    }
  }

  .total-row {
    box-shadow: 0px 2px 0px var(--v-athensGray-base), 2px 0px  0px var(--v-athensGray-base), -2px 0px 0px var(--v-athensGray-base);
    background: var(--v-athensGray-base) !important;

    td {
      border-bottom: 1px solid var(--v-blueHaze-base);

      &.total-col--hidden {
          border-bottom: none;
        }

      &:last-child:not(.total-col--hidden) {
        border-right: 1px solid var(--v-blueHaze-base);
      }
    }

    .total-col--hidden + .total-col--hidden {
      box-shadow: -2px 0px 0px var(--v-athensGray-base);
    }
  }

  .total-col {
    padding: 0 23px !important;
  }
}

.dynamic-table::v-deep {
  position: relative;

  table {
    border-radius: 10px;

    th {
      &:first-child {
        border-top-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
      }
    }
  }

  .table-wrapper {
    border-radius: 10px;
    overflow: hidden;
  }

  .table-pagination {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(calc(-100% - 10px));
    padding-top: 0;
  }
}
</style>
